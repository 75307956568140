<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('dian-pu-she-zhi') }}
            <a  class="btn btn-link btn-sm" @click="toPath('shopInfo')">({{ $t('fang-wen-dian-pu') }} <i class="la la-external-link"></i>)</a>
          </h1>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('ji-chu-xin-xi') }}</h5>
      </div>
      <div class="card-body">
        <form class="" >
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('dian-pu-ming-cheng') }}<span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <input type="text" class="form-control mb-3" :placeholder="$t('dian-pu-ming-cheng')" v-model="form.Name">
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('dian-pu-logo') }}</label>
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium">{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(1)">{{ $t('xuan-ze-wen-jian') }}</div>
              </div>
              <previewBar :fileList="fileList" @remove="removeFile"></previewBar>
              <input type="file" class="hide-btn" id="fileInput" @change="uploadFile">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">
              {{ $t('dian-pu-dian-hua') }} </label>
            <div class="col-md-10">
              <input type="text" class="form-control mb-3" :placeholder="$t('dian-hua')" v-model="form.Phone">
            </div>
          </div>
          <div class="row">
            <label class="col-md-2 col-form-label">{{ $t('dian-pu-di-zhi') }} <span class="text-danger text-danger">*</span></label>
            <div class="col-md-10">
              <input type="text" class="form-control mb-3" :placeholder="$t('dian-pu-di-zhi-0')" v-model="form.Address">
            </div>
          </div>
            <!-- <div class="row">
              <div class="col-md-2">
                <label>运费 <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-10">
                <input type="number" lang="en" min="0" class="form-control mb-3" placeholder="运费" name="shipping_cost"  required>
              </div>
            </div> -->
            <!-- <div class="row">
              <label class="col-md-2 col-form-label">元标题<span class="text-danger text-danger">*</span></label>
              <div class="col-md-10">
                <input type="text" class="form-control mb-3" placeholder="元标题" name="meta_title"  required>
              </div>
            </div>
            <div class="row">
              <label class="col-md-2 col-form-label">元描述<span class="text-danger text-danger">*</span></label>
              <div class="col-md-10">
                <textarea name="meta_description" rows="3" class="form-control mb-3" required placeholder="元描述"></textarea>
              </div>
            </div> -->
            <div class="form-group mb-0 text-right">
              <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('bao-cun') }}</button>
            </div>
        </form>
      </div>
    </div>

    <!-- <form >
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0 h6">修改在线客服</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-2">
              <label>客服链接</label>
            </div>
            <div class="col-md-10">
              <div class="input-group mb-3">
                <input type="text" name="online_ervice" class="form-control mb-3" placeholder="在线客服">
              </div>
              <div class="form-group mb-0 text-right">
                <button type="submit" class="btn btn-sm btn-primary">保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form> -->
    
    <!-- <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">送货员取货点</h5>
      </div>
      <div class="card-body">
        <form class="">
          <div class="row mb-3">
              <input id="searchInput" class="controls" type="text" placeholder="请输入地点">
              <div id="map"></div>
              <ul id="geoData">
                  <li style="display: none;">完整地址: <span id="location"></span></li>
                  <li style="display: none;">邮编: <span id="postal_code"></span></li>
                  <li style="display: none;">国家: <span id="country"></span></li>
                  <li style="display: none;">维度: <span id="lat"></span></li>
                  <li style="display: none;">经度: <span id="lon"></span></li>
              </ul>
          </div> 
          <div class="row">
            <div class="col-md-2" id="">
              <label for="exampleInputuname">经度</label>
            </div>
            <div class="col-md-10" id="">
              <input type="text" class="form-control mb-3" id="longitude" name="delivery_pickup_longitude"  >
            </div>
          </div>
          <div class="row">
            <div class="col-md-2" id="">
              <label for="exampleInputuname">维度</label>
            </div>
            <div class="col-md-10" id="">
              <input type="text" class="form-control mb-3" id="latitude" name="delivery_pickup_latitude"  >
            </div>
          </div>
         
          <div class="form-group mb-0 text-right">
            <button type="submit" class="btn btn-sm btn-primary">保存</button>
          </div>
        </form>
      </div>
    </div> -->

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('lun-bo-tu-she-zhi') }}</h5>
      </div>
      <div class="card-body">
        <form class="">
          <div class="row mb-3">
            <label class="col-md-2 col-form-label">{{ $t('lun-bo-tu') }}(1500x450)</label>  
            <div class="col-md-10">
              <div class="input-group" data-toggle="aizuploader" data-type="image" data-multiple="true">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-soft-secondary font-weight-medium" >{{ $t('liu-lan') }}</div>
                </div>
                <div class="form-control file-amount" @click="addFile(2)">{{ $t('xuan-ze-wen-jian') }}</div>
              </div>
              <previewBar :fileList="fileList2" @remove="removeFile2"></previewBar>
              <small class="text-muted">{{ $t('setting-desc') }}</small>
            </div>
          </div>

          <div class="form-group mb-0 text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('bao-cun') }}</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5 class="mb-0 h6">{{ $t('she-jiao-mei-ti-lian-jie') }}</h5>
      </div>
      <div class="card-body">
        <form class="">
          <div class="form-box-content p-3">
            <div class="row mb-3">
              <label class="col-md-2 col-form-label">Facebook</label>
              <div class="col-md-10">
                <input type="text" class="form-control" placeholder="Facebook" v-model="form.Facebook" >
                <small class="text-muted">{{ $t('shi-yong-https-lian-jie') }}</small>
              </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-2 col-form-label">{{ $t('instagram') }}</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" :placeholder="$t('instagram-0')"  v-model="form.Instagram" >
                    <small class="text-muted">{{ $t('shi-yong-https-lian-jie-0') }}</small>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-md-2 col-form-label">{{ $t('tui-te') }}</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" :placeholder="$t('tui-te-0')"  v-model="form.Twitter" >
                    <small class="text-muted">{{ $t('shi-yong-https-lian-jie-1') }}</small>
                </div>
            </div>
            <!-- <div class="row mb-3">
              <label class="col-md-2 col-form-label">谷歌</label>
              <div class="col-md-10">
                  <input type="text" class="form-control" placeholder="谷歌" name="google" >
                  <small class="text-muted">使用https链接</small>
              </div>
            </div> -->
            <div class="row mb-3">
              <label class="col-md-2 col-form-label">Youtube</label>
              <div class="col-md-10">
                  <input type="text" class="form-control" placeholder="Youtube"  v-model="form.Youtube" >
                  <small class="text-muted">{{ $t('shi-yong-https-lian-jie-2') }}</small>
              </div>
            </div>
          </div>
          <div class="form-group mb-0 text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('bao-cun') }}</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
import previewBar from '@/components/fileList'
import { shopInfo, shopSetting  } from '@/api/seller'
import { fileUpload  } from '@/api/user'
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      statusList1: [],
      statusList2: [],
      hasPackage: true,
      fileList: [],
      fileList2: [],
      form: {},
      uploadObj: {},
      fileType: ''
    }
  },
  computed: {
    shopData() {
			return this.$store.state.shopInfo
		},
  },
  watch: {
    shopData(val) {
      if (val.ID) {
        this.init()
      }
    }
  },
  mounted() {
    console.log(111, this.shopData)
    if (this.shopData.ID) {
      this.init()
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path,
          query: {
            id: this.shopData.ID
          }
        })
      }
    },
    init() {
      this.form = Object.assign({}, this.shopData)
      console.log("form",this.form)
      if (this.form.Logo) {
        this.fileList = [
          {
            url: this.form.Logo
          }
        ]
      }
      if (this.form.Banner) {
        this.fileList2 = this.form.Banner.split(',').map(v => {
          return {
            url: v
          }
        })
      }
    },
    openModal() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          if (this.fileType == 1) {
            this.fileList = [{
              url: `/upload/tmp/${res.data}`
            }]
            this.uploadObj["fileList"] = res.data
          } else {
            this.fileList2 = [{
              url: `/upload/tmp/${res.data}`
            }]
            this.uploadObj["fileList2"] = res.data
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    removeFile() {
      this.fileList = []
    },
    removeFile2(i) {
      this.fileList2.splice(i, 1)
    },
    submit() {
      let form = new FormData()
      form.append('Name', this.form.Name || '')
      if(this.uploadObj.fileList !== undefined) {
        form.append('Logo', this.uploadObj.fileList)
      }
      // form.append('Logo', this.fileList.length > 0 ? this.fileList[0].url : '')
      form.append('Address', this.form.Address || '')
      form.append('Phone', this.form.Phone || '')
      if(this.uploadObj.fileList2 !== undefined) {
        form.append('Banner', this.uploadObj.fileList2)
      }
      form.append('Facebook', this.form.Facebook || '')
      form.append('Twitter', this.form.Twitter || '')
      form.append('Instagram', this.form.Instagram || '')
      form.append('Youtube', this.form.Youtube || '')
      shopSetting(form).then(res => {
        if (res.code == 0) {
          this.uploadObj = {}
          this.$toast(this.$t('she-zhi-cheng-gong'))
        } else {
          this.$toast.fail(res.msg)
        }
      })

    }
  }
}
</script>